import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import Tag from "../components/Tag";
import styles from "../styles/tags.module.scss";

const Tags = ({ data }) => {
  const {
    allMarkdownRemark: { group },
  } = data;

  return (
    <>
      <Helmet title="Tags" />
      <Layout header="Tags">
        <div className={styles.container}>
          <ul>
            {group.map((tag) => (
              <li key={tag.fieldValue}>
                <Tag tag={tag.fieldValue} count={tag.totalCount} />
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

export default Tags;
